import React from 'react';
import Header from './components/Header';
import Description from './components/Desc';
import CallToAction from './components/Cta';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <Description />
      <CallToAction />
    </div>
  );
}

export default App;
