import React from 'react';
import './Header.css';
import logo from '../img/logo.png';

function Header() {
    return (
        <header className="header">
            <img src={logo} alt="Memecoin Logo" className="logo" />
            <h1>the ticker is RAYDIUM <br /> #RetardedAutisticYellowDinoInUMad</h1>
        </header>
    );
}

export default Header;