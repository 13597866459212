import React from 'react';
import './Cta.css';

function CallToAction() {


    return (
        <section className="cta">
            <a href='https://raydium.io/swap?outputMint=3naYJtMtqKvVqNsc4XEZDSdpd4fV5r71FNzY3JZax9H4' target="_blank" rel="noopener noreferrer">
                <button className="cta-button" >
                    BUY RAYDIUM
                </button></a>
            <a href='https://t.me/RetardedAutisticYellowDinoInUMad' target="_blank" rel="noopener noreferrer">
                <button className="cta-button blue" >
                    TELEGRAM
                </button></a>
            <a href='https://x.com/retardaydium' target="_blank" rel="noopener noreferrer">
                <button className="cta-button black" >
                    X
                </button></a>
        </section>
    );
}

export default CallToAction;