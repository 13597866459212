import React from 'react';
import './Desc.css';

function Description() {
    return (
        <section className="description">
            <h2>CA</h2>
            <p>
                <b>3naYJtMtqKvVqNsc4XEZDSdpd4fV5r71FNzY3JZax9H4</b>
            </p>
        </section>
    );
}

export default Description;